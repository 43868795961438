import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
// Initialize Swiper for .popular-slider
const mainSwiper = new Swiper('.main-slider .swiper', {
    slidesPerView: 1,
    effect: 'fade',
    loop: true, // ループする
    autoplay: {
        delay: 4000, // 自動再生の遅延時間（ミリ秒）
        disableOnInteraction: false, // ユーザーの操作後も自動再生を続ける
    },
    speed: 1000, //追加（スライドスピード）
    grabCursor: true,
    modules: [Navigation, Pagination, Autoplay], // Autoplay モジュールを追加
    pagination: {
        el: '.swiper-pagination', // ページネーションの要素
    },
});
export { mainSwiper };
