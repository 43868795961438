"use strict";
const toggle = document.getElementById('js-toggle');
const nav = document.getElementById('js-nav');
const logo = document.getElementById('js-logo');
const spList = document.getElementById('js-splist');
if (toggle && nav && logo) {
    toggle.addEventListener('click', function () {
        console.log('Click event fired');
        toggle.classList.toggle('active');
        nav.classList.toggle('active');
        logo.classList.toggle('active');
        spList?.classList.toggle('active');
    });
}
